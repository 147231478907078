var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "td-content box-border",
      style: { ..._vm.style, ..._vm.content },
    },
    [
      _vm.isShowIcon
        ? _c("span", {
            staticClass: "iconfont margin-right-sm",
            class: _vm.iconName,
            staticStyle: {
              "font-size": "inherit",
              color: "inherit",
              "flex-basis": "30%",
            },
          })
        : _vm._e(),
      _c(
        "span",
        { staticStyle: { "flex-basis": "70%" }, style: _vm.backgroundColor },
        [_vm._v("0000")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }