<!--
 * @Author: 伽蓝
 * @Date: 2020-08-12 11:06:38
 * @LastEditTime: 2021-01-07 14:03:12
 * @LastEditors: 伽蓝
 * @Description: 叫号板组件
 * @FilePath: /h5-web-view/src/components/mod/CallBoardContent copy.vue
 * @代码不规范,调试两行泪
-->
<template>
  <div class="td-content box-border" :style="{...style,...content}">
    <span v-if="isShowIcon" class="iconfont margin-right-sm" :class="iconName"
      style="font-size:inherit;color:inherit;flex-basis:30%;"></span>
    <span :style="backgroundColor" style="flex-basis:70%;">0000</span>
  </div>
</template>

<script>
import { CALL_BOARD_ICON } from 'libs/BaseConfig'
export default {
  name: 'right-call-board',
  props: {
    width: { type: Number },
    height: { type: Number },
    data: { type: Object }
  },
  computed: {
    style () {
      return { width: '100%', height: '100%' }
    },
    backgroundColor () {
      return { backgroundColor: this.data.style.backgroundColor }
    },
    content () {
      const { fontFamily, style } = this.data
      const { fontSize, letterSpacing, textAlign } = style
      const justifyContent =
        textAlign === 'left'
          ? 'flex-start'
          : textAlign === 'right'
            ? 'flex-end'
            : textAlign
      const options = {
        fontSize: fontSize + 'px',
        letterSpacing: letterSpacing + 'px'
      }
      return {
        fontFamily,
        justifyContent,
        ...Object.assign({}, style, options)
      }
    },
    isShowIcon () {
      return this.data.orderChannelIcon
    },
    iconName () {
      const { orderType } = this.data
      return CALL_BOARD_ICON[orderType[0]]
    }
  }
}
</script>
<style scoped lang="stylus">
.td-content {
  position: absolute
  display: flex
  width: 100%
  height: 100%
  top: 0
  left: 0
  background-color: transparent !important
}
</style>
